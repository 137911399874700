/* Category */



.page-type-category .qty-wrapper {
font-size:1em;
margin:5px 0 10px;
}

.products-line .product-image img {
width:44%;
}

.page-type-category .products-line .addto-links-icons.addto-onimage {
float:left;
margin-top:-32px;
position:relative;
z-index:1;
padding-bottom:4px;
padding-right:4px;
margin-left:65px;
}

.page-type-category .products-line .qty-wrapper {
float:right;
margin-top:-33px;
margin-right:29px;
}

.page-type-category .category-title {
    /*margin-bottom: 1%;*/
}

.page-type-category .std dl,
.page-type-category .std p,
.page-type-category .std address, .std blockquote {
    margin: 0;
}

.page-type-category .horizontal-section .page-content
{
    margin: 0px;
    padding: 0px;
}

@media only screen and (max-width: 959px) {
    .itemgrid .item {
        padding-left: 0;
        padding-right: 0;
        margin-left: 1%;
        margin-right: 1%;
    }
    .module-category-product-listing .add-cart-wrapper button.button span span {
        padding-left: 0;
        padding-right: 0;
    }
}
@media only screen and (min-width: 768px) and (max-width: 959px) {
    .cat-listing-container .listing-column.grid12-9 {
        width: 98% !important;
    }
}