/* Cart */
.page-type-cart .cart-action-buttons button {
  margin: 3px;
}
.page-type-cart a.progress-change {
	display: none;
}

.page-type-cart .complete a.progress-change {
	display: inherit;
}

.page-type-cart #cart_progress_navi {
	display: none;
}

.page-type-cart input.quantity {
	max-width: 55px;
}


.page-type-cart .gmb-loader {
  font-size: 0;
  display: none;
  position: relative;
  height: 60px;
  width: 60px;
}
.page-type-cart .gmb-loader div {
  background: #666666;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  position: absolute;
  -webkit-animation: gmb-bounce 1.75s infinite;
  animation: gmb-bounce 1.75s infinite;
  top: 50%;
  margin-top: -20px;
}
.page-type-cart .gmb-loader div:nth-child(2) {
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
  left: 50%;
  margin-left: -15px;
}
.page-type-cart .gmb-loader div:nth-child(3) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  right: 20px;
}

@-webkit-keyframes gmb-bounce {
  10% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
    animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
    height: 10px;
  }

  14% {
    height: 20px;
  }

  15% {
    height: 10px;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.8, 0, 0.8, 1);
    animation-timing-function: cubic-bezier(0.8, 0, 0.8, 1);
  }

  35% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes gmb-bounce {
  10% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
    animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
    height: 10px;
  }

  14% {
    height: 20px;
  }

  15% {
    height: 10px;
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.8, 0, 0.8, 1);
    animation-timing-function: cubic-bezier(0.8, 0, 0.8, 1);
  }

  35% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.page-type-cart .table {
  display: block;
  text-align: center;
  margin-left: 10px;
  float: right;
}

.page-type-cart .table .cell {
  display: table-cell;
  vertical-align: middle;
}


.page-type-cart .divLoading {
	position: absolute;
	top: 50%;
	left: 50%;
	right: 50%;
	width: 300px;
}

.page-type-cart .divLoading span {
	position: relative;
	clear: both;
	display: block;
	margin-top: 10px;
	margin-left: -50px;
}

.page-type-cart .divLoader img {
	width: 50px;
	float: left;
	height: 50px;
	margin-bottom: 10px;
	background-color: #fff;
	border-radius: 40px;
}

.page-type-cart .rego-field-general {
	display: block;
	clear: both;
}

.page-type-cart #address1-clear {
	clear: both;
}

.page-type-cart .freeshipping {
	display: none;
}

.page-type-cart #opc-review .discount-form button {
	padding: 0 0;
	margin-left: 15px;
	margin-top: -5px;
}

.page-type-cart #opc-review .discount-form .input-box {
	float: left;
	width: 25%;
}

.page-type-cart #checkout-review-table-wrapper input:not(#promo):not([name="terms_conditions"]),
.page-type-cart #checkout-review-table-wrapper textarea {
	width: 70%;
}

.page-type-cart #checkout-review-table {
	margin-top: 25px;
}


.page-type-cart .order-review .discount {
	margin-bottom: 25px;
}

/* Auto-complete */
.page-type-cart .ui-autocomplete {
    height: auto !important;
    max-height: 180px !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    padding-bottom: 7px !important;
    text-align: left;
    width: 260px !important;
}

.page-type-cart #checkoutSteps {
    padding-top: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 959px) {
  .xm-grid-cart .grid-column-wrapper.grid12-8 {
    width: 56.33%;
  }
  .xm-grid-cart .grid-column-wrapper.grid12-4 {
    width: 39.67%;
  }
  .xm-grid-cart table#shopping-cart-table {
    overflow-x: scroll;
    display: block;
    max-width: 100vw;
  }
}