@media only screen and (max-width: 767px) {
    .checkout-multishipping-shipping .cell-value, .checkout-multishipping-shipping .text-right {
        text-align: left !important;
    }
    .checkout-multishipping-shipping .btn-rm {
        display: block !important;
    }
    .checkout-multishipping-shipping .search-box .search-btn-quickorder {
        top: 30px;
    }
}